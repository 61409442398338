import { useState, useEffect } from "react";
import styled from "styled-components";
import senderMakerImage from "../../static/map/sender-marker.png";
import receiverMakerImage from "../../static/map/receiver-marker.png";
import MotorcycleMarkerImage from "../../static/map/motorcycle.png";
import DamasMarkerImage from "../../static/map/damas.png";
import LaboMarkerImage from "../../static/map/labo.png";
import { calcPosition } from "../../lib/orders/charge";

function Map({ row }) {
  const appkey = "6047082de9374b1cd950c80e9265c928";
  const [mapContainer, setMapContainer] = useState(null);
  const [rider, setRider] = useState(false);
  const route = row.route;
  const [centerMap, setCenterMap] = useState(null);

  useEffect(() => {
    // 지도를 담을 영역의 DOM 레퍼런스
    const selectMap = document.getElementById("map");
    setMapContainer(selectMap);
  }, []);

  const senderLatitude = calcPosition(row.sender_latitude);
  const senderLongitude = calcPosition(row.sender_longitude);
  const receiverLatitude = calcPosition(row.receiver_latitude);
  const receiverLongitude = calcPosition(row.receiver_longitude);
  const resentRoute = route.length > 0 ? route[route.length - 1] : null; // 루트[최신] : 예약 ~ 배차

  useEffect(() => {
    if (
      // 픽업대기 ~ 완료 -> centerMap은 최신위치
      resentRoute &&
      resentRoute.latitude !== "0" &&
      resentRoute.longitude !== "0"
    ) {
      setRider(true);
      setCenterMap({
        lat: calcPosition(resentRoute.latitude),
        lon: calcPosition(resentRoute.longitude), // 최신위치의 위도,경도 {lat: 37.49473611111111, lon: 127.0549611111111}
      });
    } else {
      // 예약 ~ 배차 -> centerMap은 출발-도착지 중간지점
      setCenterMap({
        lat: (senderLatitude + receiverLatitude) / 2,
        lon: (senderLongitude + receiverLongitude) / 2, // 중간지점 {lat: 37.51028055555555, lon: 127.04296944444444}
      });
    }
  }, [
    receiverLatitude,
    receiverLongitude,
    resentRoute,
    senderLatitude,
    senderLongitude,
  ]);

  useEffect(() => {
    const renderMap = () => {
      // 지도 생성 및 객체 리턴
      const map = new window.daum.maps.Map(mapContainer, {
        center: new window.daum.maps.LatLng(centerMap.lat, centerMap.lon), // 지도 중심좌표
        level: 10, // 확대 수준
        draggable: true,
        scrollwheel: true,
      });

      let vehicleMarkerImage;
      switch (row.vehicle_type) {
        case "MOTORCYCLE":
          vehicleMarkerImage = MotorcycleMarkerImage;
          break;
        case "DAMAS":
          vehicleMarkerImage = DamasMarkerImage;
          break;
        default:
          vehicleMarkerImage = LaboMarkerImage;
      }
      // LatLngBounds(sw, ne): 사각영역 정보를 표현하는 객체 생성 (인자가 없으면 빈 영역)
      const bounds = new window.daum.maps.LatLngBounds();
      // sw or ne?
      const senderPosition = new window.daum.maps.LatLng(
        senderLatitude,
        senderLongitude
      );
      // sw or ne?
      const receiverPosition = new window.daum.maps.LatLng(
        receiverLatitude,
        receiverLongitude
      );

      new window.daum.maps.Marker({
        map: map,
        position: senderPosition,
        image: new window.daum.maps.MarkerImage(
          senderMakerImage,
          new window.daum.maps.Size(100, 90)
        ),
      });

      new window.daum.maps.Marker({
        map: map,
        position: receiverPosition,
        image: new window.daum.maps.MarkerImage(
          receiverMakerImage,
          new window.daum.maps.Size(100, 90)
        ),
      });

      const zoomControl = new window.daum.maps.ZoomControl();
      map.addControl(zoomControl, window.daum.maps.ControlPosition.RIGHT);

      if (rider) {
        const riderPosition = new window.daum.maps.LatLng(
          centerMap.lat,
          centerMap.lon
        );
        bounds.extend(riderPosition);

        const riderMarker = new window.daum.maps.Marker({
          position: riderPosition,
          image: new window.daum.maps.MarkerImage(
            vehicleMarkerImage,
            new window.daum.maps.Size(100, 121)
          ),
        });

        riderMarker.setMap(map);

        const path = route
          .filter(
            (point, index, route) =>
              point.latitude !== "0" &&
              point.longitude !== "0" &&
              (!index ||
                (route[index - 1].latitude !== point.latitude &&
                  route[index - 1].longitude !== point.longitude))
          )
          .map(
            (point) =>
              new window.daum.maps.LatLng(
                calcPosition(point.latitude),
                calcPosition(point.longitude)
              )
          );

        new window.daum.maps.Polyline({
          map,
          path,
          strokeColor: "#0a638b",
          strokeOpacity: 1,
        });
      }
      bounds.extend(senderPosition);
      bounds.extend(receiverPosition);
      map.setBounds(bounds);
    };

    if (window.daum) {
      const scripts = document.getElementsByTagName("script");
      // appkey를 가진 script를 삭제
      // "https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6047082de9374b1cd950c80e9265c928"
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(appkey) > -1) {
          document.body.removeChild(scripts[i]);
        }
      }
    }

    if (mapContainer && centerMap) {
      // 실제 지도를 그리는 Javascript API를 불러오기
      const daumMapScript = document.createElement("script");
      daumMapScript.src = `https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${appkey}`;
      document.body.appendChild(daumMapScript);
      daumMapScript.onload = () => window.daum.maps.load(renderMap);
    }
  }, [
    centerMap,
    mapContainer,
    receiverLatitude,
    receiverLongitude,
    rider,
    route,
    row.vehicle_type,
    senderLatitude,
    senderLongitude,
  ]);

  return <MapWrapper id="map"></MapWrapper>;
}

export default Map;

const MapWrapper = styled.div`
  width: 100%;
  height: 35rem;
`;
