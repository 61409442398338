import {
  OrdersOrder,
  RegistExcelOrder,
} from "../../../containers/orders/types";
import { setCostCenterTypeText } from "../../../containers/order/utils";
import { CHANNEL, VEHICLE, PAYMENTS, STATUS } from "../constants";
import {
  setHipenInPhoneNumber,
  vehicleByRiderFilter,
  vehicleOptionFilter,
  calcTotalChargeDiscountCharge,
  totalCharge,
  distanceFilter,
  calcPosition,
} from "../../orders/charge";
import { formatDate } from "../../datetime";
import type { CellObject } from "xlsx";

export type DownColumns = {
  [x: string]: any;
  key: string;
  label: string;
  format?: (
    order: OrdersOrder & RegistExcelOrder
  ) => string | number | CellObject;
};

export const ADMIN_EXCEL_COLUMNS: DownColumns[] = [
  {
    key: "channel",
    label: "채널",
    format: ({ channel }) => CHANNEL[channel],
  },
  {
    key: "is_isd_order",
    label: "구분",
    format: ({ is_isd_order }) => (is_isd_order ? "전화" : "웹"),
  },
  {
    key: "created",
    label: "접수시간",
    format: ({ created }) => formatDate(created),
  },
  {
    key: "aq_pickup_request_time",
    label: "예약시간",
    format: ({ aq_pickup_request_time }) => formatDate(aq_pickup_request_time),
  },
  {
    key: "id",
    label: "주문번호",
  },
  {
    key: "isd_order_number",
    label: "인성번호",
  },
  {
    key: "external_isd_order_number",
    label: "지방인성번호",
  },
  {
    key: "call24_order_number",
    label: "24시 화물번호",
  },
  {
    key: "corporationName",
    label: "법인명",
    format: ({ corporations_corporation }) =>
      corporations_corporation?.name || "",
  },
  {
    key: "client_department",
    label: "의뢰인 부서명",
  },
  {
    key: "client_name",
    label: "의뢰인 이름",
  },
  {
    key: "client_phone_number",
    label: "의뢰인 연락처",
    format: ({ client_phone_number }) =>
      setHipenInPhoneNumber(client_phone_number),
  },
  {
    key: "sender_name",
    label: "출발지 이름",
  },
  {
    key: "sender_staff",
    label: "출발지 담당자",
  },
  {
    key: "sender_phone_number",
    label: "출발지 연락처",
    format: ({ sender_phone_number }) =>
      setHipenInPhoneNumber(sender_phone_number),
  },
  {
    key: "sender_jibun_address",
    label: "출발지 검색주소",
  },
  {
    key: "sender_address_detail",
    label: "출발지 상세주소",
  },
  {
    key: "sender_latitude",
    label: "출발지 위도",
    format: ({ sender_latitude }) => calcPosition(sender_latitude),
  },
  {
    key: "sender_longitude",
    label: "출발지 경도",
    format: ({ sender_longitude }) => calcPosition(sender_longitude),
  },
  {
    key: "receiver_name",
    label: "도착지 이름",
  },
  {
    key: "receiver_staff",
    label: "도착지 담당자",
  },
  {
    key: "receiver_phone_number",
    label: "도착지 연락처",
    format: ({ receiver_phone_number }) =>
      setHipenInPhoneNumber(receiver_phone_number),
  },
  {
    key: "receiver_jibun_address",
    label: "도착지 검색주소",
  },
  {
    key: "receiver_address_detail",
    label: "도착지 상세주소",
  },
  {
    key: "receiver_latitude",
    label: "도착지 위도",
    format: ({ receiver_latitude }) => calcPosition(receiver_latitude),
  },
  {
    key: "receiver_longitude",
    label: "도착지 경도",
    format: ({ receiver_longitude }) => calcPosition(receiver_longitude),
  },
  {
    key: "vehicle",
    label: "차량",
    format: ({ vehicle_type, rider_name }) =>
      vehicleByRiderFilter(VEHICLE[vehicle_type], rider_name),
  },
  {
    key: "vehicle_option",
    label: "차량옵션",
    format: ({ vehicle_option, rider_name }) =>
      vehicleByRiderFilter(vehicleOptionFilter(vehicle_option), rider_name),
  },
  {
    key: "payment",
    label: "결제방식",
    format: ({ payment }) => PAYMENTS[payment],
  },
  {
    key: "is_ev_priorty",
    label: "전기차 여부",
    // DB에 존재하지 않는 값으로 excelColumnOption에 boolean값으로만 추가
    format: ({ memo }) => (memo?.includes("전기차 우선") ? "O" : "X"),
  },
  {
    key: "memo",
    label: "메모",
  },
  {
    key: "corporate_account_memo",
    label: "배송사유",
  },
  {
    key: "rider_name",
    label: "배송기사 이름",
  },
  {
    key: "rider_phone_number",
    label: "배송기사 연락처",
    format: ({ rider_phone_number }) =>
      setHipenInPhoneNumber(rider_phone_number),
  },
  {
    key: "rider_vehicle_number",
    label: "배송기사 차량번호",
  },
  {
    key: "call24OrderReceipt",
    label: "인수증 여부",
    default: true,
    // sheetJS HyperLinks
    format: ({ id, call24_order_receipt }) =>
      call24_order_receipt
        ? {
            t: "s",
            v: `${process.env.REACT_APP_ALGOQUICK_URL}/receipt/${id}`,
            l: {
              Target: `${process.env.REACT_APP_ALGOQUICK_URL}/receipt/${id}`,
            },
          }
        : "",
  },
  {
    key: "location_charge",
    label: "기본요금",
    format: ({ location_charge }) => location_charge,
  },
  {
    key: "extra_charge",
    label: "추가요금",
    format: ({ extra_charge }) => extra_charge,
  },
  {
    key: "location_extra_charge",
    label: "배송요금(기본요금 + 추가요금)",
    // DB에 존재하지 않는 값으로 excelColumnOption에 boolean값으로만 추가
    format: ({ location_charge, extra_charge }) =>
      location_charge + extra_charge,
  },
  {
    key: "delivery_charge",
    label: "지급실비",
    format: ({ delivery_charge }) => delivery_charge,
  },
  {
    key: "discount_charge",
    label: "조정금액",
    format: ({ discount_charge, corporate_credit_amt }) =>
      calcTotalChargeDiscountCharge(discount_charge, corporate_credit_amt),
  },
  {
    key: "insurance_charge",
    label: "안심배송",
    format: ({ insurance_charge }) => insurance_charge ?? 0,
  },
  {
    key: "totalCharge",
    label: "최종금액",
    format: ({ payment_charge, vat, corporate_credit_amt, insurance_charge }) =>
      totalCharge(payment_charge, vat, corporate_credit_amt, insurance_charge),
  },
  {
    key: "status",
    label: "주문상태",
    format: ({ status }) => STATUS[status],
  },
  {
    key: "allocated",
    label: "배차시간",
    format: ({ allocated }) => formatDate(allocated),
  },
  {
    key: "picked_up",
    label: "픽업시간",
    format: ({ picked_up }) => formatDate(picked_up),
  },
  {
    key: "completed",
    label: "완료시간",
    format: ({ completed }) => formatDate(completed),
  },
  {
    key: "distance",
    label: "배송거리",
    format: ({ distance }) => distanceFilter(distance),
  },
  {
    key: "auth_code",
    label: "카드승인번호",
  },
  {
    key: "username",
    label: "접수계정",
  },
  {
    key: "userDepartment",
    label: "접수계정 부서명",
    format: ({ corporations_department }) =>
      corporations_department?.name || "",
  },
  {
    key: "fullname",
    label: "접수계정 이름",
  },
  {
    key: "phone",
    label: "접수계정 연락처",
  },
  {
    key: "approval_user",
    label: "승인관리자",
    format: ({ usersUserByApprovalUserId }) =>
      usersUserByApprovalUserId
        ? `${usersUserByApprovalUserId.fullname} (${usersUserByApprovalUserId.username})`
        : "",
  },
  {
    key: "costcenter_type",
    label: "비용 청구 타입",
    // 비용 청구처 활성화시에만 출력
    format: ({ corporations_corporation, costcenter_type }) =>
      corporations_corporation?.billing_department_enable
        ? setCostCenterTypeText(costcenter_type)
        : "",
  },
  {
    key: "costcenter",
    label: "비용 청구처",
    // 비용 청구처 활성화시에만 출력
    format: ({ corporations_corporation, costcenter }) =>
      corporations_corporation?.billing_department_enable
        ? costcenter || ""
        : "",
  },
  {
    key: "costcenter_change_memo",
    label: "비용청구부서 변경 사유",
    // 비용 청구처, 비용청구부서 변경 사유 활성화시에만 출력
    format: ({ corporations_corporation, costcenter_change_memo }) =>
      corporations_corporation?.billing_department_enable &&
      corporations_corporation?.costcenter_change_memo_enable
        ? costcenter_change_memo || ""
        : "",
  },
];
