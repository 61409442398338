import graphqlQuery from "./base";
import { STATUS, SearchTarget, ChannelMenu } from "../constants/constants";
import { InquiryType } from "../../containers/corporationInquiry/types";

const CommonOrderList = `
  algoquick_orders {
    auth_code
    corporate_credit_amt
    discount_charge
    payment_charge
    vat
    insurance_charge
  }
  allocated
  aq_pickup_request_time
  call24_order_number
  call24_order_receipt
  channel
  client_department
  client_name
  client_phone_number
  completed
  corporate_account_memo
  costcenter
  costcenter_type
  costcenter_change_memo
  created
  delivery_charge
  distance
  external_isd_order_number
  extra_charge
  id
  is_isd_order
  isd_order_number
  location_charge
  memo
  payment
  picked_up
  receiver_address_detail
  receiver_jibun_address
  receiver_name
  receiver_phone_number
  receiver_staff
  rider_name
  rider_phone_number
  rider_vehicle_number
  sender_address_detail
  sender_jibun_address
  sender_name
  sender_phone_number
  sender_staff
  status
  vehicle_option
  vehicle_type
  sender_latitude
  sender_longitude
  receiver_latitude
  receiver_longitude
  usersUserByApprovalUserId {
    fullname
    username
  }
  usersUserByRelatedUserId {
    corporations_corporation {
      billing_department_enable
      costcenter_change_memo_enable
      discount_rate
      name
    }
    corporations_department {
      name
    }
    fullname
    phone
    username
  }
  users_user {
    corporations_corporation {
      billing_department_enable
      costcenter_change_memo_enable
      discount_rate
      name
    }
    corporations_department {
      name
    }
    fullname
    phone
    username
  }
`;

const getSearchQuery = (target: SearchTarget, text: string) => {
  const search = text.trim();
  if (search === "") return "";

  switch (target) {
    case "corporate_name":
      return `{
  _or: [
    { 
      users_user: { corporations_corporation: {name: {_ilike: "%${search}%"}} } 
    },
    { 
      usersUserByRelatedUserId: { corporations_corporation: {name: {_ilike: "%${search}%"}} }
    }
  ]
}`;
    case "keyword":
      return `{
  _or: [
    {isd_order_number: {_ilike: "%${search}%"}}
    {id: {_ilike: "%${search}%"}}
    {external_isd_order_number: {_ilike: "%${search}%"}}
    {call24_order_number: {_ilike: "%${search}%"}}
    {client_name: {_ilike: "%${search}%"}}
    {client_phone_number: {_ilike: "%${search}%"}}
    {sender_name: {_ilike: "%${search}%"}}
    {sender_phone_number: {_ilike: "%${search}%"}}
    {sender_address: {_ilike: "%${search}%"}}
    {sender_address_detail: {_ilike: "%${search}%"}}
    {receiver_name: {_ilike: "%${search}%"}}
    {receiver_phone_number: {_ilike: "%${search}%"}}
    {receiver_address: {_ilike: "%${search}%"}}
    {receiver_address_detail: {_ilike: "%${search}%"}}
  ]
}`;
    default:
      return "";
  }
};

type SearchStatus = {
  key: keyof typeof STATUS;
  value: boolean;
};
const getStatusQuery = (arr: SearchStatus[]) => {
  const statusIn = arr
    .reduce<string[]>((acc, cur) => {
      if (cur.value) acc.push(`"${cur.key}"`);
      return acc;
    }, [])
    .join(", ");

  return `{status: {_in: [${statusIn}]}}`;
};

export const getChannelQuery = (channel: ChannelMenu) => {
  if (channel === "algoquick")
    return `{channel: {_in: ["algoquick", "algoquick_g1", "naver", "cake"]}}`;
  else return `{channel: {_eq: "${channel}"}}`;
};

/* 주문 정보 조회 */
type SearchOrdersParams = {
  channel: ChannelMenu;
  target: SearchTarget;
  search: string;
  statusOptions: SearchStatus[];
};
export const listOrdersPeriod = (
  start: string,
  end: string,
  { channel, target, search, statusOptions }: SearchOrdersParams
) => {
  return graphqlQuery(`{
    orders_order: custom_order(
      where: {
        _and: [
          ${getChannelQuery(channel)}
          {_or: [
            {created: {_gte: "${start}", _lt: "${end}"}}
            {aq_pickup_request_time: {_gte: "${start}", _lt: "${end}"}}
            {isd_pickup_request_time: {_gte: "${start}", _lt: "${end}"}}
          ]}
          ${getSearchQuery(target, search)}
          ${getStatusQuery(statusOptions)}
        ]
      }
    ) {
      ${CommonOrderList}
    }
  }`);
};

/* 기사 평점 조회 */
export const listReviews = () => {
  return graphqlQuery(`{
    select_orderreview_list(order_by: {created: desc}) {
      created
      id
      sender_address
      sender_name
      receiver_address
      receiver_name
      rider_name
      rider_phone_number
      is_broken
      is_delayed
      is_unkind
      review
      rider_score
      fullname
    }
  }`);
};
export const getReviewData = (id: string) => {
  return graphqlQuery(`{
    orders_riderreview(where: {id: {_eq: "${id}"}}) {
      created
      id
      is_broken
      is_delayed
      is_unkind
      review
      rider_id
      rider_score
      orders_rider {
        id
        rider_name
        rider_phone_number
      }
      users_user {
        fullname
      }
    }
  }`);
};
export const getReviewInfo = (id: string) => {
  return graphqlQuery(`{
    orders_order(where: {id: {_eq: "${id}"}}) {
      id
      client_name
      client_phone_number
      sender_name
      sender_staff
      sender_phone_number
      sender_address
      sender_jibun_address
      sender_address_detail
      receiver_name
      receiver_staff
      receiver_phone_number
      receiver_address
      receiver_jibun_address
      receiver_address_detail
    }
  }`);
};
export const updateReviewDataChecker = (orderId: string, userId: string) =>
  graphqlQuery(`
    mutation {
      update_orders_riderreview_by_pk(
        pk_columns: {id: "${orderId}"},
        _set: {check_user_id: "${userId}"}
      ) {
        created
        id
        is_broken
        is_delayed
        is_unkind
        review
        rider_id
        rider_score
        orders_rider {
          id
          rider_name
          rider_phone_number
        }
        users_user {
          fullname
        }
      }
    }
  `);

/* 법인 신청 조회 */
export const getInquiriesList = () => {
  return graphqlQuery(`{
    corporations_corporationinquiry(order_by: {created: desc}) {
      created
      id
      name
      monthly_usage_amount
      api_enable
      customer_phone
      customer_email
      users_user {
        fullname
      }
    }
  }`);
};
export const getInquiryInfo = (id: string) => {
  return graphqlQuery(`{
    corporations_corporationinquiry_by_pk(id: "${id}") {
      address
      api_enable
      check_memo
      consignment
      created
      customer_email
      customer_name
      customer_phone
      id
      inquiry
      monthly_usage_amount
      name
      users_user {
        fullname
      }
    }
  }`);
};
export const updateInquiryChecker = (info: InquiryType, uuid: string) =>
  graphqlQuery(`
    mutation {
      update_corporations_corporationinquiry_by_pk(
        pk_columns: { id: ${info.id} },
        _set: {
          check_user_id: "${uuid}",
          check_memo: "${info.check_memo?.trim() || ""}",
        }
      ) {
        users_user {
          fullname
        }
      }
    }
  `);

export const getOrderWithIsdOrderNumber = (isdOrderNumber: string) => {
  return graphqlQuery(`{
    orders_order(where: {isd_order_number: {_eq: "${isdOrderNumber}"}}) {
      created
      modified
      channel
      id
      isd_order_number
      external_isd_order_number
      client_name
      client_phone_number
      sender_name
      sender_phone_number
      sender_address
      sender_jibun_address
      sender_address_detail
      receiver_name
      receiver_phone_number
      receiver_address
      receiver_jibun_address
      receiver_address_detail
      aq_pickup_request_time
      isd_pickup_request_time
      complete_request_time
      consignment
      location_charge
      extra_charge
      delivery_charge
      express
      vehicle_type
      vehicle_option
      payment
      memo
      corporate_account_memo
      rider_name
      rider_phone_number
      rider_vehicle_number
      status
      note
      sender_latitude
      sender_longitude
      receiver_latitude
      receiver_longitude
      route
      trip
      call24_order_number
      distance
      isd_distance
      sender_bcode
      receiver_bcode
      is_isd_order
      algoquick_orders {
        loading_level
        loading
        unloading
        vehicle_charge
        bulk_charge
        express_charge
        holiday_charge
        time_charge
        weather_charge
        vat
        tid
        loading_charge
        carpool_charge
        other_charge
        round_trip_charge
      }
      algoquick_kakaoreports(order_by: {id: asc}) {
        created
        id
        cmid
        phone_number
        result
        status
        manual
      }
      usersUserByRelatedUserId {
        username
        corporations_corporation {
          name
        }
        corporations_department {
          name
        }
      }
      users_user{
        username
        corporations_corporation {
          discount_rate
          name
        }
        corporations_department {
          name
        }
      }
    }
  }`);
};

export const getOrder = (id: string) => {
  return graphqlQuery(`{
    orders_order_by_pk(id:"${id}") {
      created
      modified
      channel
      id
      isd_order_number
      external_isd_order_number
      is_safety_number
      client_name
      client_phone_number
      client_department
      sender_name
      sender_staff
      sender_phone_number
      sender_address
      sender_jibun_address
      sender_address_detail
      sender_sign
      receiver_name
      receiver_staff
      receiver_phone_number
      receiver_address
      receiver_jibun_address
      receiver_address_detail
      aq_pickup_request_time
      isd_pickup_request_time
      complete_request_time
      consignment
      location_charge
      extra_charge
      delivery_charge
      express
      vehicle_type
      vehicle_option
      payment
      memo
      corporate_account_memo
      costcenter
      costcenter_type
      costcenter_change_memo
      rider_name
      rider_phone_number
      rider_vehicle_number
      status
      note
      sender_latitude
      sender_longitude
      receiver_latitude
      receiver_longitude
      route
      trip
      call24_order_number
      distance
      isd_distance
      sender_bcode
      receiver_bcode
      is_isd_order
      allocated
      picked_up
      completed
      call24_order_receipt
      algoquick_orders {
        loading_level
        loading
        unloading
        vehicle_charge
        bulk_charge
        express_charge
        holiday_charge
        time_charge
        weather_charge
        vat
        tid
        loading_charge
        other_charge
        carpool_charge
        payment_charge
        discount_charge
        corporate_credit_amt
        round_trip_charge
        card_total_amt
        insurance_charge
      }
      algoquick_kakaoreports(order_by: {id: asc}) {
        created
        id
        cmid
        phone_number
        result
        status
        manual
      }
      usersUserByRelatedUserId {
        corporations_corporation {
          billing_department_enable
          costcenter_change_memo_enable
          discount_rate
          name
        }
        corporations_department {
          name
        }
        username
      }
      users_user{
        corporations_corporation {
          billing_department_enable
          costcenter_change_memo_enable
          discount_rate
          name
        }
        corporations_department {
          name
        }
        username
      }
    }
  }`);
};

type changeableProps = {
  id: string;

  isd_order_number: string;
  external_isd_order_number: string;
  call24_order_number: string;
  location_charge: number;
  extra_charge: number;
  delivery_charge: number;
  express: boolean;
  status: string;
  note: string;
  rider_name: string;
  rider_phone_number: string;
};

export const updateOrder = (data: changeableProps) => {
  const isd_order_number =
    data.isd_order_number.trim() === ""
      ? null
      : `"${data.isd_order_number.trim()}"`;

  const external_isd_order_number =
    data.external_isd_order_number.trim() === ""
      ? null
      : `"${data.external_isd_order_number.trim()}"`;

  const call24_order_number =
    data.call24_order_number.trim() === ""
      ? null
      : `"${data.call24_order_number.trim()}"`;

  return graphqlQuery(`
    mutation {
      update_orders_order(
        where: {
          id: {_eq: "${data.id}"}},
          _set: {
            isd_order_number: ${isd_order_number},
            external_isd_order_number: ${external_isd_order_number},
            call24_order_number: ${call24_order_number},
            location_charge: ${data.location_charge || 0},
            extra_charge: ${data.extra_charge || 0},
            delivery_charge: ${data.delivery_charge || 0},
            express: ${data.express},
            status: "${data.status}",
            note: "${data.note}",
            rider_name: "${data.rider_name}",
            rider_phone_number: "${data.rider_phone_number}",
            }) {
        returning {
          id
        }
      }
    }    
  `);
};

export const updateUsersUsername = (orderId: string, userId: string | null) =>
  graphqlQuery(`
    mutation {
      update_orders_order(
        where: {
          id: {_eq: "${orderId}"}},
          _set: {user_id: ${userId ? `"${userId}"` : null}}) {
        affected_rows
      }
    }
  `);

export const updateRelatedUsername = (orderId: string, userId: string | null) =>
  graphqlQuery(`
  mutation {
    update_orders_order(
      where: {
        id: {_eq: "${orderId}"}},
        _set: {related_user_id: ${userId ? `"${userId}"` : null}}) {
      affected_rows
    }
  }
`);

export const searchRider = (
  senderBcode: string,
  receiverBcode: string,
  vehicleType: string
) => {
  let query: string;

  if (vehicleType === "TRUCK") {
    query = `{
      sender_bcode: {_eq: "${senderBcode}"},
      receiver_bcode: {_eq: "${receiverBcode}"},
      rider_name: {_neq: ""},
      rider_phone_number: {_neq: "16660131"},
      vehicle_type: {_eq: "TRUCK"},
    }`;
  } else {
    query = `{
      sender_bcode: {_eq: "${senderBcode}"},
      receiver_bcode: {_eq: "${receiverBcode}"},
      rider_name: {_neq: ""},
      rider_phone_number: {_neq: "16660131"},
      vehicle_type: {_neq: "MOTORCYCLE"},
    }`;
  }

  return graphqlQuery(`{
  orders_order(where: {
  _and: ${query}
  },order_by: {created: desc}) {
    id
    created
    rider_name
    rider_phone_number
    assigned_charge
    assigned_vehicle_option
  }}
  `);
};
